/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CustomFooter from './CustomFooter';
import { main } from '../theme';
import GdprCookies from './GdprCookies';
import HeaderLayout from './layout/HeaderLayout';

import ModalSuscribe from '../components/layout/ModalSuscribre';


const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: '110px',
      marginBottom: '15px',
      fontSize: '45px',
      fontWeight: 900,
    },
    container: {
      paddingRight: '15px',
      paddingLeft: '15px',
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100%',
      '@media (min-width: 576px)': {
        maxWidth: '540px',
      },
      '@media (min-width: 768px)': {
        maxWidth: '720px',
      },
      '@media (min-width: 992px)': {
        maxWidth: '960px',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1140px',
      },
    },
  })
);

interface Props {
  children: React.ReactNode;
  title?: string;
  description?: string;
  img?: string;
  href?: string;
  showFooter?: boolean;
  // newHeader?: boolean;
}

const Layout = ({
  children,
  title,
  description,
  img,
  showFooter = true,
  // newHeader = false,
}: Props): JSX.Element => {
  const classes = useStyles();
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
  return (
    <>
      <ThemeProvider theme={main}>
        <CssBaseline />
        <HeaderLayout ticker={false} openModal={openSuscribe}/>
        {/* { newHeader ? 
          <HeaderLayout />
          :
          <>
            <Header />
            <HidingAppBar />
          </>

        } */}
        <div className={classes.container}>
          <div style={{ marginTop: 60 }}>
            {title && title !== 'Vector Análisis | Vector Casa de Bolsa' && (
              <>
                <h1 style={{ color: 'black', marginTop: 100, fontSize: 60 }}>
                  {title}
                </h1>
              </>
            )}
            {children}
          </div>
        </div>
        {showFooter && <CustomFooter openModal={openSuscribe}/>}
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
        <GdprCookies />
      </ThemeProvider>
    </>
  );
};

export default Layout;
