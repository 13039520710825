import * as React from 'react';
import { graphql, Link } from 'gatsby';
import CategoryLayout from '../components/CategoryLayout';
import useIsClient from '../utils/isClient';

interface Props {
  data: {
    tag: any[];
    carteraModelo: any[];
    carteraTecnico: any[];
    diarioEconomico: any[];
    flashEconomico: any[];
    flashEmisora: any[];
    flashInternacional: any[];
    flashRentaFija: any[];
    flashTecnico: any[];
    pulsoTecnico: any[];
    mercadosApertura: any[];
    estrategiaDeInversion: any[];
    expectativasFinancierasAnuales: any[];
    expectativasReporteTrimestral: any[];
    inicioDeCobertura: any[];
    reporteTrimestral: any[];
  };
}

export default function InicioDeCobertura({ data }: Props): JSX.Element {
  const isClient = useIsClient();
  if(isClient){
    return (
      <CategoryLayout title={data.tag.edges[0].node.nombre}>
        {data.carteraModelo.edges !== undefined &&
          data.carteraModelo.edges.length > 0 && (
            <>
              <br />
              <h3>Cartera Modelo</h3>
              <ul>
                {data.carteraModelo.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {/* {data.carteraTecnico.edges !== undefined &&
          data.carteraTecnico.edges.length > 0 && (
            <>
              <br />
              <h3>Cartera Técnico</h3>
              <ul>
                {data.carteraTecnico.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/tecnico/carteraTecnico/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )} */}
  
        {data.diarioEconomico.edges !== undefined &&
          data.diarioEconomico.edges.length > 0 && (
            <>
              <br />
              <h3>Diario Económico</h3>
              <ul>
                {data.diarioEconomico.edges.map(function (r: any) {
                  return (
                    <Link key={r.node.titulo} to={`/economico/${r.node.slug}`}>
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.flashEconomico.edges !== undefined &&
          data.flashEconomico.edges.length > 0 && (
            <>
              <br />
              <h3>Flash Económico</h3>
              <ul>
                {data.flashEconomico.edges.map(function (r: any) {
                  return (
                    <Link key={r.node.titulo} to={`/economico/${r.node.slug}`}>
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.flashEmisora.edges !== undefined &&
          data.flashEmisora.edges.length > 0 && (
            <>
              <br />
              <h3>Flash Emisora</h3>
              <ul>
                {data.flashEmisora.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.flashInternacional.edges !== undefined &&
          data.flashInternacional.edges.length > 0 && (
            <>
              <br />
              <h3>Flash Internacional</h3>
              <ul>
                {data.flashInternacional.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/internacional/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.flashRentaFija.edges !== undefined &&
          data.flashRentaFija.edges.length > 0 && (
            <>
              <br />
              <h3>Flash Renta Fija</h3>
              <ul>
                {data.flashRentaFija.edges.map(function (r: any) {
                  return (
                    <Link key={r.node.titulo} to={`/renta-fija/${r.node.slug}`}>
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.flashTecnico.edges !== undefined &&
          data.flashTecnico.edges.length > 0 && (
            <>
              <br />
              <h3>Flash Técnico</h3>
              <ul>
                {data.flashTecnico.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      //to={`/tecnico/flash/${r.node.slug}`}
                      to={`/tecnico/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.mercadosApertura.edges !== undefined &&
          data.mercadosApertura.edges.length > 0 && (
            <>
              <br />
              <h3>Mercados a la apertura</h3>
              <ul>
                {data.mercadosApertura.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/mercados-apertura/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.pulsoTecnico.edges !== undefined &&
          data.pulsoTecnico.edges.length > 0 && (
            <>
              <br />
              <h3>Diario Técnico</h3>
              <ul>
                {data.pulsoTecnico.edges.map(function (r: any) {
                  return (
                    <Link key={r.node.titulo} to={`/tecnico/${r.node.slug}`}>
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.estrategiaDeInversion.edges !== undefined &&
          data.estrategiaDeInversion.edges.length > 0 && (
            <>
              <br />
              <h3>Estrategia de Inversión</h3>
              <ul>
                {data.estrategiaDeInversion.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/estrategia-inversion/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.expectativasFinancierasAnuales.edges !== undefined &&
          data.expectativasFinancierasAnuales.edges.length > 0 && (
            <>
              <br />
              <h3>Expectativas Financieras Anuales</h3>
              <ul>
                {data.expectativasFinancierasAnuales.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.expectativasReporteTrimestral.edges !== undefined &&
          data.expectativasReporteTrimestral.edges.length > 0 && (
            <>
              <br />
              <h3>Expectativas Reporte Trimestral</h3>
              <ul>
                {data.expectativasReporteTrimestral.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.inicioDeCobertura.edges !== undefined &&
          data.inicioDeCobertura.edges.length > 0 && (
            <>
              <br />
              <h3>Inicio de Cobertura</h3>
              <ul>
                {data.inicioDeCobertura.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
  
        {data.reporteTrimestral.edges !== undefined &&
          data.reporteTrimestral.edges.length > 0 && (
            <>
              <br />
              <h3>Reporte Trimestral</h3>
              <ul>
                {data.reporteTrimestral.edges.map(function (r: any) {
                  return (
                    <Link
                      key={r.node.titulo}
                      to={`/renta-variable/${r.node.slug}`}
                    >
                      <li>{r.node.titulo}</li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
      </CategoryLayout>
    );
  }else{
    return(<></>)
  }

}

export const pageQuery = graphql`
  query($tag: String!) {
    tag: allContentfulTag(filter: { nombre: { eq: $tag } }) {
      edges {
        node {
          nombre
          slug
        }
      }
    }

    carteraModelo: allContentfulCarteraModelo(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          slug
          titulo
        }
      }
    }

    carteraTecnico: allContentfulCarteraTecnico(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    diarioEconomico: allContentfulDiarioEconomico(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    flashEconomico: allContentfulFlashEconomico(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    flashEmisora: allContentfulFlashEmisora(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    flashInternacional: allContentfulFlashInternacional(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          slug
          titulo
        }
      }
    }

    flashRentaFija: allContentfulFlashRentaFija(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    flashTecnico: allContentfulFlashTecnico(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          slug
          titulo
        }
      }
    }

    pulsoTecnico: allContentfulPulsoTecnico(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          slug
          titulo
        }
      }
    }

    mercadosApertura: allContentfulMercadosALaApertura(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    estrategiaDeInversion: allContentfulEstrategiaDeInversion(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    expectativasFinancierasAnuales: allContentfulExpectativasFinancierasAnuales(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    expectativasReporteTrimestral: allContentfulExpectativasReporteTrimestral(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    inicioDeCobertura: allContentfulInicioDeCobertura(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }

    reporteTrimestral: allContentfulReporteTrimestral(
      filter: { tags: { elemMatch: { nombre: { eq: $tag } } } }
    ) {
      edges {
        node {
          titulo
          slug
        }
      }
    }
  }
`;
